<template>
  <div>
    <m-bread-crumb/>

<section class="contact spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-8">
                    <div class="contact__text">
                        <div class="section-title">
                            <h2>Crédito Rural Agro-Pecuário</h2>
                            <p>
                            Destinado ao financiamento de bens e serviços relacionados a manutenção da Agro-Pecuária tais como aquisição de animais, ração, medicamentos, equipamento e outros.
                            </p>
                        </div>
                       
                    </div>
                </div>
                
                <div class="col-lg-6 col-md-6">
                    <div class="car__details__pic">
                        <div class="car__details__pic__large">
                            <img class="car-big-img" src="img/cars/details/cd-22.jpg" alt="">
                        </div>
                        
                    </div>
                </div>
                
               
            </div>
            
        <div class="col-lg-12" >
            <h2 style="text-align: center"><b>Vantagens</b></h2>
            <br>
            <br>
        <div class="about__feature">
                <div class="row">
                    
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about__feature__item">
                            <img src="img/about/af-1.png" alt="">
                            <h5>Entrega Rapida</h5>
                            <p>Fazemos desembolso ate 3 dias uteis.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about__feature__item">
                            <img src="img/about/af-2.png" alt="">
                            <h5>Qualidade de dados</h5>
                            <p>Somos responsaveis pela qualidade de dados.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="about__feature__item">
                            <img src="img/about/af-3.png" alt="">
                            <h5>Taxas fixas</h5>
                            <p>Temos taxas fixas ate ultima prestacao.</p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
        
</section>


<section class="about spad">
    <div class="container">
        <div class="row">

<div id="accordion" class="col-lg-12">
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          Finalidade
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            O Crédito Rural Comércio tem como objetivo garantir ao produtor rural todos os recursos e condições que garantam a satisfatória comercialização dos seus produtos no mercado
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> 
          Público – Alvo
        </button>
              
      </h5>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            Para clientes particulares e empresas.
        </ul>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Vantagens
        </button>
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Investimento que se adequa ao seu projecto e a sua capacidade financeira <br />
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Liberta capitais próprios da Empresa para serem utilizados em outras necessidades. <br />
                
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
          Despesas e comissões
        </button>
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>	
            As despesas e comissões decorrentes do processo de financiamento serão debitadas automaticamente na conta do mutuário, de acordo com o plano financeiro acordado.<br />

            Em caso de liquidação antecipada, total ou parcial, do capital em dívida fica sujeito a penalização.        
        </ul>
      </div>
    </div>
  </div>
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Características
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>	Todas as operações estão sujeitas aos critérios da análise de risco/garantias associadas.<br>
		          <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Modalidade de empréstimo de curto e médio prazo (até 3 anos).<br>
		          
                    </p>
                </ul>
        </div>
     
        
    </div>
  </div>

    <div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0">
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          Documentação
        </button>
      </h5>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
         
        <div class="card card-body">
                <ul>
                    <p><i class="fa fa-chevron-circle-right" style="color:red;"></i>	O negócio deve existir pelo menos há seis meses<br>
		          <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Fotocopia do BI, Cartão de contribuinte e licenças da empresa reconhecida<br>
                <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Prova de pagamento do imposto pela empresa<br>
                <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Estatutos da empresa<br>
                <i class="fa fa-chevron-circle-right" style="color:red;"></i>	Extracto bancário dos últimos seis meses<br>
		          
                    </p>
                </ul>
        </div>
     
        
    </div>
  </div>
    
</div>    

        </div>
    </div>
    
</section>

   
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "Credito Rural",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>